
<template>
  <div class="panel-margin">
    <TitleComponent
      @title-TITLE_UPDATED="updateItem"
      :placeholder="$t('fields.title')"
      :item="item">
    </TitleComponent>
    <Footer :item="item"></Footer>
  </div>
</template>

<script>
import TitleComponent from './title/TitleComponent'
import Footer from '../Footer'

export default {
  components: {
    TitleComponent,
    Footer,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    parent_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {}
  },
  methods: {
    updateItem (item, type, value) {
      if (this.item.id === item.id) {
        const data = this._.cloneDeep(this.item.data)
        data.title = value
        item.data = data
        this.$parent.$emit('item-ITEM_UPDATED', this.item)
      }
    }
  },
  created() {
    this.$on('title-TITLE_UPDATED', (item, type, value) => {

    })
    this.$on('footer-DELETE_ITEM', (item) => {
      this.$parent.$emit('item-DELETE_ITEM', item)
    })

    this.$on('footer-DUPLICATE_ITEM', (item) => {
      this.$parent.$emit('item-DUPLICATE_ITEM', item)
    })

    this.$on('footer-ITEM_UPDATED', (item) => {
      this.$parent.$emit('item-ITEM_UPDATED', item)
    })
  },

  mounted() {
    this.$emit('questionRendered')
  }
}
</script>

<style>
</style>
